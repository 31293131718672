import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";

function SearvicesAbout(props) {
  return (
    
    <section class="white-section parralax-section pb-xs-25 pb-md-25 pb-lg-60 pt-xs-50 pt-md-50 pt-lg-100 ovh">
        <Container>
					<div class="section-header">
						<h2>What We Do Best</h2>
						<p>Our team will help you find the missing piece of your music with state-of-the-art equipment and years of experience</p>
					</div>
        </Container>
				<div class="container-fluid ">
					<div class="tile-price-column">
			
					</div>
				</div>

        <div class="massonry-grid tile-price-grid tile-price-wrap">
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
              <img src={require('../styles/img/tile-price-1.jpg')} class="text-img lazy" data-src="./img/tile-price-1.jpg" alt="bg" />
							
							<div class="text">
								<div>
									<h2 class="font-gradient">Production<br/>Management</h2>
								</div>
								{/* <div class="price">
									<span><span>$</span>35</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
							
              <img src={require('../styles/img/tile-price-3.jpg')} class="text-img lazy" data-src="./img/tile-price-3.jpg" alt="bg" />
							<div class="text">
								<div>
									<h2 class="font-gradient">Staffing<br/>Resources</h2>
									{/* <p>Specializing in mixing for music. Stereo and 5.1 capabilities</p> */}
								</div>
								{/* <div class="price">
									<span><span>$</span>55</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
							
              <img src={require('../styles/img/tile-price-5.jpg')} class="text-img lazy" data-src="./img/tile-price-5.jpg" alt="bg" />
							<div class="text">
								<div>
									<h2 class="font-gradient">Account<br/>Management</h2>
									{/* <p>We outsource all mastering services. Ask us who we use</p> */}
								</div>
								{/* <div class="price">
									<span><span>$</span>55</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
							
              <img src={require('../styles/img/tile-price-2.jpg')} class="text-img lazy" data-src="./img/tile-price-2.jpg" alt="bg" />
							<div class="text">
								<div><h2 class="font-gradient">Full Service<br/>Solutions</h2></div>
								{/* <div class="price">
									<span><span>$</span>75</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
							
              <img src={require('../styles/img/tile-price-6.jpg')} class="text-img lazy" data-src="./img/tile-price-6.jpg" alt="bg" />
							<div class="text">
								<div><h2 class="font-gradient">Client<br/>Relations</h2></div>
								{/* <div class="price">
									<span><span>$</span>35</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
					<a href="#" class="gallery-grid__item" target="_blank">
						<div class="tile-price-card">
							
              <img src={require('../styles/img/tile-price-4.jpg')} class="text-img lazy" data-src="./img/tile-price-4.jpg" alt="bg" />
							<div class="text">
								<div>
									<h2 class="font-gradient">Artist<br/>Relations</h2>
									{/* <p>Songwriting, arranging, session playing, producing</p> */}
								</div>
								{/* <div class="price">
									<span><span>$</span>95</span>
									hour
								</div> */}
							</div>
						</div>
					</a>
				</div>
      
    </section>
  );
}

export default SearvicesAbout;
