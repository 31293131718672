import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "./HeroSection.scss";

function HeroSection(props) {
  return (
    <div className="hero-section-main">
      {/* <div class="overlay"></div> */}
      {/* <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src={require('../styles/img/hic-video.mp4')} type="video/mp4" />
      </video> */}
      <div className="hero-with-bg">
        <Container>
          <Row className="align-items-center justify-content-center height-full">
            <Col md={10} className="text-center">
              <h4 className="slider-sub-head">Logistics and Staffing Solutions</h4>
              <h1>Audio & General Production Resources</h1>
              {/* <img src={require('../styles/img/hic-logo.png')} /> */}
              <a href="#" className="btn btn-primary mt-50">Get Started</a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    // <Section
    //   bg={props.bg}
    //   textColor={props.textColor}
    //   size={props.size}
    //   bgImage={props.bgImage}
    //   bgImageOpacity={props.bgImageOpacity}
    // >
    //   <Container>
    //     <Row className="align-items-center">
    //       <Col lg={5} className="text-center text-lg-left">
    //         <SectionHeader
    //           title={props.title}
    //           subtitle={props.subtitle}
    //           size={1}
    //           spaced={true}
    //         ></SectionHeader>
    //         <Button
    //           variant={props.buttonColor}
    //           size="lg"
    //           onClick={props.buttonOnClick}
    //         >
    //           {props.buttonText}
    //         </Button>
    //       </Col>
    //       <Col className="offset-lg-1 mt-5 mt-lg-0 ">
    //         <figure className="HeroSection__image-container mx-auto">
    //           <Image src={props.image} fluid={true}></Image>
    //         </figure>
    //       </Col>
    //     </Row>
    //   </Container>
    // </Section>
  );
}

export default HeroSection;
