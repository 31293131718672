import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";
import Slider from "react-slick";


function TeamPageSection(props) {

	return (
		<section class="white-section parralax-section pt-xs-50 pt-md-50 pt-lg-120 pb-xs-50 pb-md-50 pb-lg-120">
			<Container>
				<div class="section-header">
					<h2>Our Team Members</h2>
					<p>Collectively Hundreds of years of industry knowledge and idea-farming experience</p>
				</div>
				<Row className="justify-content-center">
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">CJ C.</h4></a>
								<p class="subheader">Founder / Lead Operator / Lead Developer</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Kylee G.</h4></a>
								<p class="subheader">Business Manager / Financial/Payroll</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Sean H.</h4></a>
								<p class="subheader">H.R. / Staffing Coordinator</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Bobby B.</h4></a>
								<p class="subheader">West Coast Production Management / Artist Management Liasson</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Ryan K.</h4></a>
								<p class="subheader">East Coast Production Management / Onsite Technical Director/I.A.T.S.E. project coordinator</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Michael O.</h4></a>
								<p class="subheader">Live Mixer, Systems Design, Electrical Engineer</p>
							</div>
						</div>
					</Col>
					<Col md={3}>
						<div class="item matchHeight team-item item-type-circle">
							<a href="#" class="image">
								<img width="1057" height="1400" src={require('../styles/img/artist_01.jpg')} class="attachment-full size-full" alt="" sizes="(max-width: 1057px) 100vw, 1057px" />
							</a>
							<div class="descr"> <a href="#"><h4 class="header">Vickie D.</h4></a>
								<p class="subheader">Employee Housing / Facilities Coordinator</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<img src={require('../styles/img/paralax-3.png')} class="parallax-img animate v-center tt-paroller lazy" data-src="img/paralax-3.png" data-paroller-factor="0.18" data-paroller-type="foreground" alt="bg" />
			<img src={require('../styles/img/paralax-4.png')} class="parallax-img parallax-right v-bottom anim-delay animate tt-paroller lazy" data-src="img/paralax-4.png" data-paroller-factor="0.12" data-paroller-type="foreground" alt="bg" />
		</section>
  	);
}

export default TeamPageSection;
