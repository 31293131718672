import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";

function ServiceManagement(props) {
  return (
    <section class="white-section parralax-section">
      <div class="text-section text-position-right ovh pt-xs-25 pt-md-25 pt-lg-150 pb-xs-25 pb-md-25 pb-lg-100">
        <Container>
          <Row className="align-items-center">
            <Col md={12} lg={6}>
                <div class="text-section_video mb-xs-20 mb-lg-0">
									<h2>Service Management</h2>
									<div class="img-wrap js-tilt mb-xs-20 mb-md-0">
										<img src={require('../styles/img/text-block-video-1.png')} class="text-img lazy" data-src="./img/text-block-video-1.png" alt="bg" />
										<img src={require('../styles/img/text-block-video-bg.png')} class="text-img-bg lazy" data-src="./img/text-block-video-bg.png" alt="img" />
									</div>
								</div>
            </Col>
            <Col md={12} lg={6}>
              <div class="text-block">
									<h2>Service Management</h2>
									<p class="h-sub">Hear It Clear has vast team of world class production leaders, all with unique career experiences that speak for themselves.</p>
									<p>No matter what role you need filled for your event, Hover over their smile to let your next production manager, or audio engineer say “Hi!”  Save a couple favorites “for later shows,” or click “Book Now” to immediately reserve someone you need-tomorrow.  Please note that Hear It Clear is an equal opportunity and unbiased entity. </p>
									<p>We go the extra mile for our clients and employees, and uphold the collective bargaining agreements of all HIC service members for fair and honest compensation.</p>
								</div>
            </Col>
          </Row>
        </Container>
        <img src={require('../styles/img/paralax-4.png')} class="parallax-img parallax-right animate tt-paroller lazy" data-src="img/paralax-4.png" data-paroller-factor="0.15" data-paroller-type="foreground" alt="bg"></img>
        {/* <img src={require('../styles/img/paralax-4.png')} className="parallax-img animate tt-paroller lazy" data-paroller-factor="0.15" data-paroller-type="foreground" alt="bg"></img> */}
      </div>
    </section>
  );
}

export default ServiceManagement;
