import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";

function AllPageHead(props) {
  return (
    
    <div className="all-page-head"></div>
  );
}

export default AllPageHead;
