import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features.scss";

function HICImaginears(props) {
  return (
    <section class="white-section v-separator parralax-section pb-xs-25 pb-md-25 pb-lg-60">
      <div class="text-section text-position-right ovh pt-xs-25 pt-md-25 pt-lg-150 pb-xs-25 pb-md-25 pb-lg-100">
        <Container>
          <Row className="align-items-center">
            <Col md={12} lg={6}>
                <div class="text-section_video mb-xs-20 mb-lg-0">
									<h2>Stream It Clear Imaginears</h2>
									<div class="img-wrap js-tilt mb-xs-20 mb-md-0">
										<img src={require('../styles/img/text-block-video-2.png')} class="text-img lazy" data-src="./img/text-block-video-2.png" alt="bg" />
										<img src={require('../styles/img/text-block-video-bg.png')} class="text-img-bg lazy" data-src="./img/text-block-video-bg.png" alt="img" />
									</div>
								</div>
            </Col>
            <Col md={12} lg={6}>
              <div class="text-block">
									<h2>Stream It Clear Imaginears</h2>
									<p class="h-sub">Stream It Clear is THE agency for any artist, management, or production house to call for any needs.</p>
									{/* <p>The Disney Company created Imagineers. Stream It Clear (H.I.C.) gives birth to Imagin<strong>ears</strong>. HIC follows the model of a staffing service on and off your clock, prepared for any event: <strong>“what do you need? It’s done.”</strong></p> */}
                  <p><strong>Prepared and Automated</strong></p>
									<p>With the concert industry growing (~U.S. GDP +17%/year,) and the streaming industry’s vertical integration, our world’s talent pool has grown to need and expect MORE.</p>
								</div>
            </Col>
          </Row>
        </Container>
        <img src={require('../styles/img/paralax-4.png')} class="parallax-img parallax-right animate tt-paroller lazy" data-src="img/paralax-4.png" data-paroller-factor="0.15" data-paroller-type="foreground" alt="bg"></img>
        {/* <img src={require('../styles/img/paralax-4.png')} className="parallax-img animate tt-paroller lazy" data-paroller-factor="0.15" data-paroller-type="foreground" alt="bg"></img> */}
      </div>
    </section>
  );
}

export default HICImaginears;
