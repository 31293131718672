import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth.js";

function NavbarCustom(props) {
  const auth = useAuth();

  return (
    <div>
    <div class="header-wrap">
			<header>
				{/* <a href="index.html" class="logo">
					<img src="data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=" class="lazy" data-src="img/logo.svg" alt="logo" />
					<span class="logo-text">
						<span>VOYCEO</span>
						<span>RECORDING STUDIO</span>
					</span>
				</a> */}
        <div className="d-flex align-items-center">
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="50"
            ></img>
          </Navbar.Brand>
        </LinkContainer>
        <div class="border blink">
          <div class="livecont">
            {/* <div class="light blink"></div> */}
            LIVE
          </div>
        </div>
        </div>
        
				<div class="header-right">
        <nav  class="main-nav" data-menu-gradient>
						<ul class="menu">
							<li class="">
                <LinkContainer to="/">
                  <Nav.Link active={true}>HOME</Nav.Link>
                </LinkContainer>
              </li>
							<li>
                <LinkContainer to="/about">
                  <Nav.Link active={false}>ABOUT</Nav.Link>
                </LinkContainer>
							</li>
							{/* <li>
                <LinkContainer to="/team">
                  <Nav.Link active={false}>MEET OUR TEAM</Nav.Link>
                </LinkContainer>
              </li> */}
							<li>
                <LinkContainer to="/service-management">
                  <Nav.Link active={false}>SERVICE MANAGEMENT</Nav.Link>
                </LinkContainer>
              </li>
							<li><a href="#">INVEST</a></li>
							<li>
                <LinkContainer to="/contact">
                  <Nav.Link active={false}>CONTACT</Nav.Link>
                </LinkContainer>
              </li>
						</ul>
						<span class="menu-gradient"></span>
					</nav>
          <div class="hamburger">
						<span></span>
						<span></span>
						<span></span>
					</div>
        </div>
      </header>
    </div>
    {/* <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="30"
            ></img>
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle
          aria-controls="navbar-nav"
          className="border-0"
        ></Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            {auth.user && (
              <NavDropdown id="dropdown" title="Account" alignRight={true}>
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/settings/general">
                  <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
                </LinkContainer>

                <Dropdown.Divider></Dropdown.Divider>

                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to="/auth/signin">
                  <Nav.Link active={false}>Sign in</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar> */}
    </div>
  );
}

export default NavbarCustom;
