import React from "react";
import HeroSection2 from "../components/HeroSection2";
import AllPageHead from "../components/AllPageHead"
import TeamBiosSection from "../components/TeamBiosSection";
import TeamPageSection from "../components/TeamPageSection";

function OurTeamPage(props) {
  return (
    <>
      <AllPageHead />
      <TeamPageSection />
      
    </>
  );
}

export default OurTeamPage;
