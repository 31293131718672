import React from "react";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import AboutUs from "./../components/AboutUs";
import FeaturesSection from "./../components/FeaturesSection";
import CurrentProjects from "./../components/CurrentProjects";
import OurTeam from "./../components/OurTeam";
import CTA from "./../components/CTA";
import HICImaginears from "./../components/HICImaginears";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        buttonColor="primary"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      ></HeroSection>
      <AboutUs />
      {/* <CurrentProjects /> */}
      {/* <OurTeam /> */}
      {/* <CTA /> */}
      {/* <HICImaginears /> */}
      
    </>
  );
}

export default IndexPage;
