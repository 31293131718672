import React from "react";
import HeroSection2 from "../components/HeroSection2";
import TeamBiosSection from "../components/TeamBiosSection";
import ServiceManagement from "../components/ServiceManagement";
import AvailableStaff from "../components/AvailableStaff";
import AllPageHead from "../components/AllPageHead";
import CTA from "../components/CTA";

function ServiceManagementPage(props) {
  return (
    <>
      <AllPageHead />
      <ServiceManagement />
      <CTA />
      <AvailableStaff />
      
    </>
  );
}

export default ServiceManagementPage;
