import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";
import HICImaginearsAbout from "./../components/HICImaginearsAbout";
import ServicesAbout from "./../components/ServicesAbout";
import AllPageHead from "./../components/AllPageHead";
import CTA from "./../components/CTA";

function AboutPage(props) {
  return (
    <>
      <AllPageHead />
      <HICImaginearsAbout />
      <CTA />
      <ServicesAbout />
      
    </>
  );
}

export default AboutPage;
