import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import Slider from "react-slick";
import "./Features.scss";

function AvailableStaff(props) {
  const settings = {
    centerMode: true, 
    centerPadding: "29.5%",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "22%",
          arrows:true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0%",
          arrows: false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3500
        }
      }
    ]
  }
  return (
    
    <section class="block-bg-projects parralax-section v-separator pt-xs-25 pt-md-25 pt-lg-60 pb-xs-25 pb-md-25 pb-lg-60">
      <div class="ovh">
        <Container className="pt-xs-25 pt-md-25 pt-lg-60 pb-xs-25 pb-md-25 pb-lg-60">
          <Row>
            <Col md={12}>
                <div class="section-header">
                  <h2>Available Staff</h2>
                  <p>List of Available Staff from Stream It Clear. </p>
                </div>
                <Slider className="project-slider arrow-outside"
                  { ...settings}
                >
                    <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                            {/* <source type="image/webp" srcset="img/project-1.jpg" />
                            <source type="image/jpeg" srcset="img/project-1.jpg" /> */}
                            {/* <img src="img/project-1.jpg" alt="img" /> */}
                            <img src={require('../styles/img/artist_01.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">Bobby B.</a>
                        <p class="theme-color">FOH</p>
                      </div>
                    </div>

                    <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                            {/* <source type="image/webp" srcset="img/project-2.jpg" />
                            <source type="image/jpeg" srcset="img/project-2.jpg" />
                            <img src="img/project-2.jpg" alt="img" /> */}
                            <img src={require('../styles/img/artist_01.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">Ryan K.</a>
                        <p class="theme-color">RF Tech</p>
                      </div>
                    </div>

                    <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                            {/* <source type="image/webp" srcset="img/project-3.jpg" />
                            <source type="image/jpeg" srcset="img/project-3.jpg" />
                            <img src="img/project-3.jpg" alt="img" /> */}
                            <img src={require('../styles/img/artist_01.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">Michale O.</a>
                        <p class="theme-color">Lightning Designer</p>
                      </div>
                    </div>

                    <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                            {/* <source type="image/webp" srcset="img/project-4.jpg" />
                            <source type="image/jpeg" srcset="img/project-4.jpg" />
                            <img src="img/project-4.jpg" alt="img" /> */}
                            <img src={require('../styles/img/artist_01.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">Vickie D.</a>
                        <p class="theme-color">Account Manager</p>
                      </div>
                    </div>

                    {/* <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                             <source type="image/webp" srcset="img/project-5.jpg" />
                            <source type="image/jpeg" srcset="img/project-5.jpg" />
                            <img src="img/project-5.jpg" alt="img" /> 
                            <img src={require('../styles/img/project-5.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">American Girl</a>
                        <p class="theme-color">Bonnie McKee</p>
                      </div>
                    </div> */}

                    {/* <div class="tt-slide">
                      <div class="project-card">
                        <a href="#" class="project-cart_logo">
                          <picture>
                            <source type="image/webp" srcset="img/project-6.jpg" />
                            <source type="image/jpeg" srcset="img/project-6.jpg" />
                            <img src="img/project-6.jpg" alt="img" />
                            <img src={require('../styles/img/project-6.jpg')} alt="img" />
                          </picture>
                        </a>
                        <a href="#" class="project-cart_name">American Girl</a>
                        <p class="theme-color">Bonnie McKee</p>
                      </div>
                    </div> */}

                </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default AvailableStaff;
